import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LabourIcon } from '../assets/rose.svg';

const topic = {
  "title": "UK Regions and Nations",
  "results": {
    "deal": {
      "status": "red",
      "comment": "No"
    },
    "disorderly": {
      "status": "red",
      "comment": "No"
    },
    "remain": {
      "status": "green",
      "comment": "Yes"
    },
    "reform": {
      "status": "green",
      "comment": "Yes"
    }
  }
};

const LabRegions = () => (
  <Topic topic={topic} icon={LabourIcon}>
    <h2>Does it deliver for all regions and nations of the UK?</h2>
    <p>
      Labour originally said they would only back a deal that passed these 6 tests. Jeremy Corbyn has since put forward 5 new terms instead.
    </p>
  </Topic>
);

export default LabRegions;
