import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const styles = theme => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-55px',
    },
    [theme.breakpoints.up('md')]: {
      background: '#FFFFFF',
      borderStyle: 'solid',
      borderWidth: '0px 5px 0px 0px',
      borderColor: '#BB7A00',
      borderRadius: '0px 5px 5px 0px',
    },
    justifyContent: 'center',
  },
  button: {
    width: '120px',
    height: '36px',
    background: '#FFFFFF',
    borderStyle: 'solid',
    borderColor: '#03215d',
    borderWidth: '2px',
    borderRadius: '18px',
    textTransform: 'none',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: '#000080',
    textAlign: 'center',
    '&:hover': {
      background: '#03215d',
      borderColor: '#FFFFFF',
      color: '#FFFFFF',
    },
  },
});

const LearnMore = ({ classes, className, onClick }) => (
  <Grid item xs={12} md className={classNames(classes.gridItem, className)}>
    <div className={classNames(classes.cell, className)}>
      <Button
        className={classNames(classes.button, className)}
        onClick={onClick}
      >
        <span>Learn more</span>
      </Button>
    </div>
  </Grid>
);

LearnMore.propTypes = {};

export default withStyles(styles)(LearnMore);
