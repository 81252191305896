import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as GoodIcon } from '../../assets/mark-good.svg';
import { ReactComponent as BadIcon } from '../../assets/mark-bad.svg';
import { ReactComponent as ProblemIcon } from '../../assets/mark-problem.svg';
import { ReactComponent as QuestionIcon } from '../../assets/mark-question.svg';

const styles = theme => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  result: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '50px', // to fit the 'learn more' box in
    },
  },
  /* @todo Change these to use nth-child method and remove classes,
   *       may need to move to topic.jsx component.
   */
  left: {
    background: '#FBFCFE',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'solid',
      borderWidth: '0px 0px 5px 0px',
      borderColor: '#BB7A00',
      borderRadius: '0px 0px 0px 5px',
    },
  },
  center: {
    background: '#F1F4FB',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'solid',
      borderWidth: '0px 0px 5px 0px',
      borderColor: '#BB7A00',
    },
  },
  center2: {
    background: '#FBFCFE',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'solid',
      borderWidth: '0px 0px 5px 0px',
      borderColor: '#BB7A00',
      borderRadius: '0px 0px 5px 0px', // Apply border radius here as removing last col
    },
  },
  right: {
    background: '#F1F4FB',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'solid',
      borderWidth: '0px 0px 5px 0px',
      borderColor: '#BB7A00',
      borderRadius: '0px 0px 5px 0px',
    },
  },
  comment: {
    fontSize: '12px',
    color: '#02215D',
    textAlign: 'center',
    lineHeight: '13px',
  },
});

const statuses = {
  red: (
    <BadIcon className="fa-2x"/>
  ),
  amber: (
    <ProblemIcon className="fa-2x"/>
  ),
  green: (
    <GoodIcon className="fa-2x"/>
  ),
  blue: (
    <QuestionIcon className="fa-2x"/>
  ),
};

const Result = ({ classes, className, position, status, comment }) => (
  <Grid item xs className={classNames(classes.gridItem, className)}>
    <div className={classNames(classes.result, classes[position], className)}>
      <div>
        {statuses[status]}
        <div className={classNames(classes.comment, className)} dangerouslySetInnerHTML={{ __html: comment }} />
      </div>
    </div>
  </Grid>
);

export default withStyles(styles)(Result);
