import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LabourIcon } from '../assets/rose.svg';

const topic = {
  "title": "Rights and Protections",
  "results": {
    "deal": {
      "status": "red",
      "comment": "No"
    },
    "disorderly": {
      "status": "red",
      "comment": "No"
    },
    "remain": {
      "status": "green",
      "comment": "Yes"
    },
    "reform": {
      "status": "green",
      "comment": "Yes"
    }
  }
};

const LabDynamicProtections = () => (
  <Topic topic={topic} icon={LabourIcon}>
    <h2>&ldquo;Dynamic alignment on rights and protections&rdquo;, so that UK standards do not fall behind those of the EU.</h2>
    <p>
      One of Labour's five demands for a sensible agreement that can win the support of parliament.
    </p>
  </Topic>
);

export default LabDynamicProtections;
