import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as LabourIcon } from '../assets/rose.svg';

const topic = {
  "title": "A Permanent Customs Union",
  "results": {
    "deal": {
      "status": "red",
      "comment": "No"
    },
    "disorderly": {
      "status": "red",
      "comment": "No"
    },
    "remain": {
      "status": "green",
      "comment": "Yes"
    },
    "reform": {
      "status": "green",
      "comment": "Yes"
    }
  }
};

const LabCustomsUnion = () => (
  <Topic topic={topic} icon={LabourIcon}>
    <h2>A &ldquo;permanent and comprehensive UK-wide customs union&rdquo;, including a say in future trade deals.</h2>
    <p>
      One of Labour's five demands for a sensible agreement that can win the support of parliament.
    </p>
  </Topic>
);

export default LabCustomsUnion;
