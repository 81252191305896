import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { faFacebookSquare } from '@fortawesome/fontawesome-free-brands';
import { faWhatsappSquare } from '@fortawesome/fontawesome-free-brands';
import { faTwitterSquare } from '@fortawesome/fontawesome-free-brands';
import { faLinkedin } from '@fortawesome/fontawesome-free-brands';

import ShareIcon from '../ShareIcon';
//import './share.scss';

const styles = theme => ({
  postSocial: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    position: 'relative',
    cursor: 'pointer',
    lineHeight: '1',
    // Style the share icon here
    '& .shareIcon': {
      position: 'relative',
      display: 'block',
      height: '1em',
      width: '1em',
      margin: '0',
      padding: '0',
      lineHeight: '1',
      borderRadius: '4px',
      color: '#fff',
      zIndex: '2',
      transition: 'all 300ms ease-out',
    },
    /* Add a rectangle behind the Icon,
     * to aide in having a background colour
     * FA Icons SVG has spacing top and bottom
     * so can't apply to button style directly
     */
    '&::before': {
      position: 'absolute',
      top: '0.25em',
      left: '0.25em',
      // Bottom is .215em for the Facebook Icon
      bottom: '0.215em',
      right: '0.25em',
      display: 'block',
      content: '" "',
      borderRadius: '6px',
      backgroundColor: 'transparent',
      zIndex: '1',
      transition: 'all 300ms ease-out',
    },
    // Hover change colours
    '&:hover': {
      '& .shareIcon': {
        color: '#03215d',
      },
      '&::before': {
        backgroundColor: '#fff',
      },
    },
  },
});

const Share = ({ classes, className, socialConfig, tags }) => (
  <div className={classNames(classes.postSocial, className)}>
    <FacebookShareButton
      url={socialConfig.config.url}
      className={classNames(classes.button, className)}
      quote="See how Johnson/May's deal fares alongside No Deal or Remain. Compare The Brexit at comparethebrexit.com"
      ariaLabel="Share on Facebook"
    >
      <ShareIcon icon={faFacebookSquare} />
    </FacebookShareButton>
    <WhatsappShareButton
      url={socialConfig.config.url}
      className={classNames(classes.button, className)}
      title="See how the Johnson/May's deal fares alongside No Deal or Remain. Compare The Brexit at comparethebrexit.com"
      separator=" "
      ariaLabel="Share on WhatsApp"
    >
      <ShareIcon icon={faWhatsappSquare} />
    </WhatsappShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      className={classNames(classes.button, className)}
      title="See how Johnson/May's deal fares alongside No Deal or Remain. Compare The Brexit at"
      via={socialConfig.twitterHandle.split('@').join('')}
      hashtags={[]}
      ariaLabel="Share on Twitter"
    >
      <ShareIcon icon={faTwitterSquare} />
    </TwitterShareButton>
    <LinkedinShareButton
      url={socialConfig.config.url}
      className={classNames(classes.button, className)}
      title={socialConfig.config.title}
      description="See how Johnson/May's deal fares alongside No Deal or Remain. Compare The Brexit at comparethebrexit.com"
      ariaLabel="Share on Linkedin"
    >
      <ShareIcon icon={faLinkedin} />
    </LinkedinShareButton>
  </div>
);

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
  tags: [],
};

export default withStyles(styles)(Share);
