import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  introText: {
    fontSize: '16px',
    color: '#FFFFFF',
    lineHeight: '20px',
    marginBottom: '30px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  }
});

const IntroText = ({ classes, className, children }) => (
  <Grid container justify="center">
    <Grid item xs={11} md={8}>
      <div className={classNames(classes.introText, className)}>
        {children}
      </div>
    </Grid>
  </Grid>
);

IntroText.defaultProps = {
  children: "We've studied Boris Johnson/Theresa May's withdrawal agreement/Brexit deal to see how it compares to a no deal Brexit or remaining in the EU. See below how they differ on the key issues that will affect people's day to day lives.",
};

export default withStyles(styles)(IntroText);
