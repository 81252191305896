import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  cell: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      // height: "80px",
    },
  },
  header: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundImage: 'linear-gradient(-23deg, #BB7A00 0%, #FFAF18 100%)',
    borderRadius: '5px 0px 0px 5px',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      justifyContent: 'center',
      borderRadius: '5px 5px 0px 0px',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 14px 0 12px',
      fontSize: '13px',
      textAlign: 'left',
    },
  },
  icon: {
    marginRight: '12px',
    fontSize: '36px',
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

const TopicHeader = ({ classes, className, icon, title }) => {
  const IconComponent = icon;
  return (
    <Grid item xs={12} md className={classNames(classes.cell, className)}>
      <div className={classNames(classes.header, className)}>
        <div className={classNames(classes.label, className)}>{title}</div>
        <div className={classNames(classes.icon, className)}>
          <IconComponent />
        </div>
      </div>
    </Grid>
  );
};

TopicHeader.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(TopicHeader);
