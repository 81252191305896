import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Link } from "gatsby"

import Share from '../Share';

const url = 'https://www.comparethebrexit.com';

const styles = theme => ({
  promptBoxes: {
    marginTop: '12px',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    flex: '1',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#2e4d9e',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.50)',
    borderWidth: '0px 0px 5px 0px',
    borderStyle: 'solid',
    borderColor: '#2e4d9e #2e4d9e #8da0c6 #2e4d9e',
    borderRadius: '5px',
    padding: '10px',
  },
  text: {
    fontSize: '18px',
    color: '#FFFFFF',
    textAlign: 'center',
    margin: '10px',
  },
  mpRecolor: {
    borderWidth: '0px',
    backgroundColor: '#FFFFFF',
  },
  mpTextRecolor: {
    color: '#094ACB',
  },
  button: {
    width: '200px',
    height: '48px',
    margin: '10px',
    backgroundColor: '#15AF00',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    borderWidth: '1px',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
    '&:hover': {
      background: '#03215d',
      borderColor: '#FFFFFF',
      color: '#FFFFFF',
    },
  },
  socialRecolor: {
    borderWidth: '0px',
    backgroundColor: '#094ACB',
  },
});

const PromptBoxes = ({ classes, className, recolor }) => (
  <Grid container spacing={16} justify="space-between" alignContent="stretch" className={classNames(classes.promptBoxes, className)}>

    <Grid
      item
      xs={12}
      sm={6}
      className={classNames(classes.gridItem, className)}
    >
      <div
        className={classNames(
          classes.cell,
          recolor ? classes.mpRecolor : undefined,
          className
        )}
      >
        <div
          className={classNames(
            classes.text,
            recolor ? classes.mpTextRecolor : undefined,
            className
          )}
        >
          Do you have concerns about leaving the EU?
        </div>
        <div>
          <Button
            component={OutboundLink}
            href="https://hey-mp.uk/?utm_source=comparethebrexit"
            target="_blank"
            className={classNames(classes.button, className)}
          >
            Contact your MP
          </Button>
        </div>
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      className={classNames(classes.gridItem, className)}
    >
      <div
        className={classNames(
          classes.cell,
          recolor ? classes.socialRecolor : undefined,
          className
        )}
      >
        <div className={classNames(classes.text, className)}>
          Share to help others Compare the Brexit too!
        </div>
        <Share
          socialConfig={{
            twitterHandle: '@comparebrexit',
            hashtags: ['FinalSay'],
            config: {
              url,
              title: 'CompareTheBrexit',
            },
          }}
        />
      </div>
    </Grid>

  </Grid>
);

export default withStyles(styles)(PromptBoxes);
