import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Link } from 'gatsby';

const styles = theme => ({
  topicHeader: {
    marginBottom: '26px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  pillTitle: {
    borderColor: '#fff',
    borderWidth: '2px',
    textTransform: 'none',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    margin: '0',
    backgroundColor: '#03215d',
    borderRadius: '5px',
    width: '342px',
    maxWidth: '100%',
  }
});

const TopicHeader = ({ classes, className, title }) => (
  <Grid container spacing={16} justify="left" alignItems="center" className={classNames(classes.topicHeader, className)}>
    <Grid item xs={12} sm={3} align="left">
      <Link to="/" className={classNames(classes.link, className)}>&lt; Compare another issue</Link>
    </Grid>
    <Grid item xs={12} sm={6} align="center">
      <h2 className={classNames(classes.pillTitle, className)}>{title}</h2>
    </Grid>
  </Grid>
);

TopicHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(TopicHeader);
