import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import LabCustomsUnion from '../topics/lab-customs-union';
import LabSingleMarket from '../topics/lab-single-market';
import LabDynamicProtections from '../topics/lab-dynamic-protections';
import LabEUAgencies from '../topics/lab-eu-agencies';
import LabSecurity from '../topics/lab-security';

import LabCollaborate from '../topics/lab-collaborate';
import LabSameBenefits from '../topics/lab-same-benefits';
import LabMigration from '../topics/lab-migration';
import LabRaceToBottom from '../topics/lab-race-to-bottom';
import LabNationalSecurity from '../topics/lab-national-security';
import LabRegions from '../topics/lab-regions';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const Labour = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Labour" shareImage="labour" description="See how various Brexits satisfy Labour's Tests" />
    <IntroText>
      We've studied Theresa May's withdrawal agreement to see how it compares to a no deal Brexit or remaining in the EU. See below how they differ on the key issues outlined by Labour.
    </IntroText>
    <TopicHeader title="Labour's terms for supporting May's&nbsp;deal" />
    <DealHeadersRow />
    <LabCustomsUnion />
    <LabSingleMarket />
    <LabDynamicProtections />
    <LabEUAgencies />
    <LabSecurity />
    {/*<TopicHeader title="Labour's six tests" />*/}
    <LabCollaborate />
    <LabSameBenefits />
    <LabMigration />
    <LabRaceToBottom />
    <LabNationalSecurity />
    <LabRegions />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

Labour.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Labour.defaultProps = {
  data: {},
};

export default withRoot(Labour);
