import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  text: {
    color: '#999999',
    fontSize: '0.7em',
    textAlign: 'center',
    margin: '20px',
  }
});

const Notes = ({ classes, className }) => (
  <Grid container justify="center">
    <Grid item xs={11} md={8}>
      <div className={classNames(classes.text, className)}>
        <p>*WTO rules assumed for trading<br/>
          **'Deal' refers to Johnson's deal unless otherwise stated
        </p>
      </div>
    </Grid>
  </Grid>
);

export default withStyles(styles)(Notes);
