import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const fullWidth = {
  xs: '68px',
  sm: '116px',
  md: '150px',
};

const halfWidth = {
  xs: '34px',
  sm: '58px',
  md: '75px',
};

const styles = theme => ({
  header: {
    height: '66px',
    position: 'relative',
    margin: '0px 2px -20px 2px', // overlap onto TopicHeader and provide horizontal spacing
    padding: '0px',
    overflowX: 'hidden', // hide overflow of the diagnnals on mobile viewport
    filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.75))',
  },
  background: {
    backgroundColor: '#02215D',
  },
  main: {
    height: '40px',
    borderRadius: '5px 5px 0px 0px',
    borderColor: '#02215D',
    borderStyle: 'solid',
    borderWidth: '5px 0px 0px 0px',
  },
  diagonal: {
    position: 'absolute',
    height: '10px',
    bottom: '16px', // position from the bottom for arrow overflow (20px margin on header container)
  },
  left: {
    left: '0',
    right: '49.8%',   // use percentage positining as headers always fill container
    transform: 'skewY(7deg)',
    transformOrigin: 'top right',
    // boxShadow: '0px 3px 4px rgba(0,0,0,0.75)',
  },
  right: {
    left: '49.8%',
    right: '0',
    transform: 'skewY(-7deg)',
    transformOrigin: 'top left',
    // boxShadow: '0px 3px 4px rgba(0,0,0,0.75)',
  },
  text: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: '49px',
    fontSize: '14px',
    color: '#ffffff',
    width: '100%',
    padding: '0px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px', // smaller font size on mobile to fix text overlap
    },
  },
});

const DealHeader = ({ classes, className, children }) => (
  <Grid item xs>
    <div className={classNames(classes.header, className)}>
      <div
        className={classNames(classes.background, classes.main, className)}
      />
      <div
        className={classNames(
          classes.background,
          classes.diagonal,
          classes.left,
          className
        )}
      />
      <div
        className={classNames(
          classes.background,
          classes.diagonal,
          classes.right,
          className
        )}
      />
      <span className={classNames(classes.text, className)}>{children}</span>
    </div>
  </Grid>
);

export default withStyles(styles)(DealHeader);
