import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import DealHeader from '../DealHeader';

const styles = theme => ({
  dealHeaders: {
    position: 'sticky',
    top: 0,
    backgroundImage: 'radial-gradient(closest-side at 59% 107%, #265FD0 19%, #042D7C 100%)',
    zIndex: 999,
  }
});

const DealHeadersRow = ({ classes, className }) => (
  <Grid container spacing={0} justify="space-evenly" className={classNames(classes.dealHeaders, className)}>
    <Grid item xs={12} md/>
    <DealHeader>No Deal*</DealHeader>
    <DealHeader>Deal**</DealHeader>
    <DealHeader>Revoke Article 50</DealHeader>
    {/* <DealHeader>Remain and Reform</DealHeader> */}
    <Grid item xs={12} md/>
  </Grid>
);

export default withStyles(styles)(DealHeadersRow);
