import React from 'react'
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import TopicHeader from './topicHeader';
import Result from './result';
import LearnMore from './learnMore';
import PromptBoxes from '../PromptBoxes';

const styles = theme => ({
  row: {
    marginBottom: "4px",
  },
  dialogXs: {
    margin: "8px",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundImage: "linear-gradient(-23deg, #BB7A00 0%, #FFAF18 100%)",
    padding: "24px",
    [theme.breakpoints.down('xs')]: {
      padding: "16px",
    },
  },
  dialogIcon: {
    height: "50px",
    fontSize: "50px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "36px",
    },
  },
  dialogLabel: {
    flexGrow: 1,
    color: "#ffffff",
    fontSize: "26px",
    paddingLeft: "20px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "18px",
    },
  },
  dialogDismiss: {
    fontSize: "32px",
    color: "#ffffff",
    '&:hover': {
      color: "#cccccc",
    }
  },
  dialogContent: {
    margin: "20px",
    fontSize: "14px",
    color: "#191919",
    lineHeight: "22px",
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
    },
  }
});

class Topic extends React.Component{
  state = {
    open: false,
  };

  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  };

  handleClose() {
    this.setState({ open: false });
  };

  render() {
    const { classes, className, topic, icon, children } = this.props
    const IconComponent = icon;
    return (
      <div>
        <Grid container spacing={0} alignContent="stretch" justify="space-evenly" className={classNames(classes.row, className)}>
          <TopicHeader icon={icon} title={topic.title}/>
          <Result position="left" {...topic.results.disorderly} />
          <Result position="center" {...topic.results.deal} />
          <Result position="center2" {...topic.results.remain} />
          { /* <Result position="right" {...topic.results.reform} /> */ }
          <LearnMore onClick={this.handleOpen} />
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          classes={{
            paperWidthXs: classNames(classes.dialogXs, className),
            paperWidthSm: classNames(classes.dialogXs, className),
          }}
        >
          <div className={classNames(classes.dialogTitle, className)}>
            <IconComponent className={classNames(classes.dialogIcon, className)}/>
            <div className={classNames(classes.dialogLabel, className)}>{topic.title}</div>
            <div className="push">
              <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} className={classNames(classes.dialogDismiss, className)} />
            </div>
          </div>
          <DialogContent className={classNames(classes.dialogContent, className)}>
            {children}
            <PromptBoxes recolor />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
};

Topic.propTypes = {
  topic: PropTypes.object.isRequired,
  icon: PropTypes.func.isRequired
};

export default withStyles(styles)(Topic);
